import React from 'react'
import { Typography } from '@material-ui/core'

function About() {
  return (
    <>
      <Typography variant="body1">Edit About component or pages/about.tsx to include your information.</Typography>
    </>
  )
}

export default About
